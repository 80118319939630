import "./style.css";
import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, ThemeProvider, createTheme,Button } from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailIcon from "@mui/icons-material/Mail";
import logo from "../../images/logo.jpg"
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@mui/icons-material/Translate';
import i18n from "../trans";
import { Link, useLocation } from "react-router-dom";
const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1280,
    },
  },
});

function Header() {
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
 

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeLink, setActiveLink] = useState("home");



  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    setActiveLink(currentPath === "/" ? "home" : currentPath.slice(1));
  }, [currentPath]);
  
  useEffect(() => {
      const pageTitle = location.pathname.split('/').pop();
      if (pageTitle===""){
        // eslint-disable-next-line no-useless-concat
        document.title =  'Taxi Blainville'; 
      }else{
      document.title = `Taxi Blainville - ${pageTitle.replace(/%20/g, ' ')}`; 
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <ThemeProvider theme={customTheme}>
      <div className="header" id="header">
        <div className="navbar">
     
          <img src={logo} alt="logo" className="logo"/>
          <div className="right-nav">
            <ol className={isOpen ? "none" : "linkList"}>
              <li>
                <Link
                  to="/"
                  className={
                    activeLink === "home" ? "navLink active" : "navLink"
                  }
                >
                    {t('acceuil')}
                </Link>
              </li>
             
              <li>
                <Link
                  className={
                    activeLink === "about" ? "navLink active" : "navLink"
                  }
                  to="/about"
                >
                  {t('propos')}
                </Link>
              </li>
              <li>
                <Link
                  className={
                    activeLink === "services"
                      ? "navLink active"
                      : "navLink"
                  }
                  to="/services"
                >
                   {t('services')}
                </Link>
              </li>
              <li>
                <Link
                  className={
                    activeLink === "contacts" ? "navLink active" : "navLink"
                  }
                  to="/contacts"
                >
                   {t('contacts')}
                </Link>
              </li>
              <li>
                <div className="socialLinks">
              <a href="tel:(+1)438-221-0201" className="socialLink">
              <PhoneAndroidIcon color="warning"
              />
              </a>
            <a href="mailto:commercial@sanitek.tn" className="socialLink">
                    <MailIcon color="warning"/>
                  </a>
        
            
              
                </div>
              </li>
            </ol>
            <Button   sx={{
            marginLeft: { lg:'20px',md:'20px' ,sm:'200px', xs: "20px" }, zIndex:99999,
          }}  color="primary" startIcon={<TranslateIcon/>} onClick={() => changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>
  {i18n.language === 'en' ? 'Fr' : 'En'}
</Button>
          </div>
          <IconButton
            sx={{
              display: {
                lg: "none",
                md: "none",
                sm: "inline-block",
                xs: "inline-block",
              },
              top: 2,
              right: "10px",
              zIndex: 99,
            }}
            onClick={toggleMenu}
            
          >
            {!isOpen ? (
              <MenuIcon
                id="openIcon"
                sx={{ color: "#008DBD" }}
                fontSize="small"
              />
            ) : (
              <CloseIcon
                id="closeIcon"
                sx={{ color: "#008DBD" }}
                fontSize="medium"
              />
            )}
          </IconButton>
          <div className={isOpen ? "linkMenu show" : "linkMenu"}>
            
            <ol id="navMenu">
              <li>
                <a
                  className={
                    activeLink === "home" ? "navLink active" : "navLink"
                  }
                  href="/"
                >
                  {t('acceuil')}
                </a>
              </li>
              <li className="separation"></li>
        
              <li>
                <a
                  className={
                    activeLink === "promotions" ? "navLink active" : "navLink"
                  }
                  href="/about"
                >
                  {t('propos')}
                </a>
              </li>

              <li className="separation"></li>

              <li>
                <a
                  className={
                    activeLink === "fournisseurs&references"
                      ? "navLink active"
                      : "navLink"
                  }
                  href="/services"
                >
                  services
                </a>
              </li>
              <li className="separation"></li>
              <li>
                <a
                  className={
                    activeLink === "contacts" ? "navLink active" : "navLink"
                  }
                  href="/contacts"
                >
                  Contacts
                </a>
              </li>
              <li className="separation"></li>
              <li>
                <div className="socialLinks">
                <span>
              <a href="tel:(+1)438-221-0201" className="socialLink">
              <PhoneAndroidIcon color="primary"
              />
              </a>
            </span>
            <span>
            <a href="mailto:medglaa@outlook.com" className="socialLink">
                    <MailIcon  color="primary"/>
                  </a>
            </span>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
export default Header;
