import "./style.css";
import React from "react";
import Footer from "../../Components/Footer";
import { Button } from "@mui/material";
import ScrollToTop from "../../Components/scrollup";
import servicePic from '../../images/servicePic1.jpg'
import serv1 from '../../images/serv1.jpg'
import serv2 from '../../images/serv2.jpg'
import serv3 from '../../images/serv3.jpg'
import serv4 from '../../images/serv4.jpeg'
import serv5 from '../../images/serv5.png'
import serv6 from '../../images/serv6.jpg'
import serv7 from '../../images/serv7.jpg'
import serv8 from '../../images/serv8.png'
import { useTranslation } from 'react-i18next';

export default function Services() {
    const { t } = useTranslation();
  return (
    <div className="service">
        <ScrollToTop />
        <img src={servicePic} alt="couverture-quebec" className="couverturePic" />
        
        <h2>{t('service1')} </h2>
       <p>{t('service2')}</p>
      <div className="serviceDetails">
      
          <div>
          <a href="/book">
          <img src={serv1} alt="service" className="serviceDetailPic" />
          <span>{t('service3')} 24/7</span>
          </a>
          </div>
         
          <div>
          <a href="/book">
          <img src={serv2} alt="service" className="serviceDetailPic" />
          <span>{t('service4')}</span>
          </a>
          </div>
          <div>
          <a href="/book">
          <img src={serv3} alt="service" className="serviceDetailPic" />
          <span>{t('service5')}</span>
          </a>
          </div>
          <div>
          <a href="/book">
          <img src={serv5} alt="service" className="serviceDetailPic" />
          <span>{t('service6')}</span>
          </a>
          </div>
          <div>
          <a href="/book">
          <img src={serv4} alt="service" className="serviceDetailPic" />
          <span>{t('service7')}</span>
          </a>
          </div>
          <div>
          <a href="/book">
          <img src={serv6} alt="service" className="serviceDetailPic" />
          <span>{t('service8')}</span>
          </a>
          </div>
          <div>
          <a href="/book">
          <img src={serv7} alt="service" className="serviceDetailPic" />
          <span>{t('service9')}</span>
          </a>
          </div>
          <div>
          <a href="/book">
          <img src={serv8} alt="service" className="serviceDetailPic" />
          <span>{t('service10')}</span>
          </a>
          </div>
      </div>
      <h2>{t('service11')}</h2>
        <p>{t('service12')}</p>   
        <div>
        <a href="/book">
        <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: 2 }}
          >
           {t('commander')}
          </Button>
          </a>
          </div>
      <Footer/>
    </div>
  );
}
