/* eslint-disable jsx-a11y/iframe-has-title */
import "./style.css";
import React from "react";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import { Button } from "@mui/material";
import contactPic from "../../images/contactPic.png"
import contactPic1 from "../../images/contactPic1.jpg"
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailIcon from "@mui/icons-material/Mail";
import { useTranslation } from 'react-i18next';
export default function Contact() {
  const { t } = useTranslation();
  return (
    <div className="contact" id="contact">
         <img src={contactPic} alt="couverture-quebec" className="couverturePic" />
         
      <ScrollToTop />
        <h2 className="locationTitle">{t('contact1')}</h2>
        <div className="contactDiv">
        <img src={contactPic1} alt="taxi" className="homePic2" />
        <div>
         
          <h3>Contact:</h3>
            <span>
              <PhoneAndroidIcon
                sx={{
                  color: "#008DBD",
                  width: { lg: "50px", md: "50px", sm: "32px", xs: "22px" },
                }}
              />{" "}
              <a href="tel:(+1)438-221-0201" className="contact-link">
                (+1) 438-221-0201
              </a>
            </span>
            <span>
              <MailIcon
                sx={{
                  color: "#008DBD",
                  width: { lg: "50px", md: "50px", sm: "32px", xs: "22px" },
                }}
              />{" "}
              <a href="mailto:medglaa@outlook.com" className="contact-link">
              medglaa@outlook.com
              </a>
            </span>
            <span>
            <a href="/book">
          <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: 4,marginLeft:'15px' }}
          >
            {t('commander')}
          </Button>
          </a>
          </span>
        </div>
         
        </div>
        <div className="contactDiv">
          <div>
            <LocationOnIcon
            color="warning"
              sx={{
                width: { lg: "72px", md: "50px", sm: "32px", xs: "24px" }
              }}
            />
            <p className="locationP">
            1039 RUE DE LA BRETAGNE BLAINVILLE QUÉBEC J7C2Z8
            </p>
            </div>
            <div>
            <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2787.5192731863726!2d-73.88027222382753!3d45.680550171078224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc8d62b37857605%3A0xc6616946d18cc6f5!2s1039%20Rue%20De%20La%20Bretagne%2C%20Blainville%2C%20QC%20J7C%202Z8%2C%20Canada!5e0!3m2!1sfr!2stn!4v1718192334712!5m2!1sfr!2stn"
            style={{ border: 0, borderRadius: "8px" }}
            loading="lazy"
          ></iframe>
        </div>
        </div>
        
       
      <Footer />
    </div>
  );
}
