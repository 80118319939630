
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: {
          acceuil: 'accueil',
          propos: 'a propos',
          services: 'services',
          contacts: 'contacts',
          commander:'commander un taxi',
          contacter:'contactez-nous',
          home1: 'VOUS CHERCHEZ UN TAXI ?',
          home2 :'BIENVENUE CHEZ TAXI BLAINVILLE',
          home3:`Votre partenaire de confiance pour tous vos déplacements en toute sécurité et confort. Que ce soit pour un transfert aéroportuaire ou une simple course en ville, nous sommes là pour vous offrir une solution personnalisée. Réservez dès maintenant votre prochain trajet avec nous et profitez d'un service de qualité à Québec et ses environs !`,
          home4:'TAXI BLAINVILLE : UNE SOLUTION RAPIDE AU BOUT DU FIL',
          home5:'Faites appel aux services de Taxi Blanville à toute heure du jour et de la nuit! Profitez de services de taxi fiables, ponctuels et attentionnés et voyagez à bord de véhicules dotés de tous les équipements indispensables à votre confort et à votre sécurité. Pour toute question ou demande de renseignements, nous vous invitons à nous contacter par téléphone ou par courriel.',
          home6:'DES SERVICES DIVERSIFIÉS POUR VOUS FACILITER LA VIE',
          home7:"Peu importe votre destination ! Profitez de tarifs fixes avantageux sur certains itinéraires et services particulièrement prisés. Explorez-les dès maintenant pour planifier votre prochaine escapade en toute sérénité!",
          home8:'Notre priorité : votre satisfaction',
          home9:'Notre objectif premier est votre entière satisfaction. Disponibles 24h/24 et 7j/7, nous sommes là pour répondre à toutes vos demandes de transport. Que ce soit pour une réservation en ligne ou par téléphone, nous vous invitons à réserver dès maintenant votre prochain trajet et à nous laisser vous accompagner en toute tranquillité lors de vos déplacements.',
          foot1:'adresse',
          foot2:"1039 rue de la bretagne Blainville Québec J7C2Z8",
        foot3:"horaires",
        foot4:"zones de service",
      about1:"Pour voyager confortablement et rapidement, choisissez Blainville Taxi",
     about2:"qui-sommes-nous?",
     about3:"Chez Taxi Blainville, nous ne sommes pas simplement une compagnie de transport, mais bien plus. Notre fondation repose sur une vision claire : fournir des solutions de déplacement pratiques, fiables et innovantes. Nous nous engageons à offrir une expérience de voyage exceptionnelle à chacun de nos passagers.",
     about4:"des solutions de transport exceptionnelles sont à disposition",
     about5:"Disponibilité",
     about6:"Taxi Blainville est là pour vous, que vous ayez un vol tard dans la nuit ou une réunion tôt le matin" ,
     about7:"Des prix compétitifs",
     about8:"Nous vous offrons des tarifs compétitifs sans sacrifier la qualité du service. Notre engagement est de vous fournir un excellent rapport qualité-prix",
    about9:"sécurité d'abord",
    about10:"Nous mettons votre sécurité en avant-plan. Nos chauffeurs agréés sont soumis à des vérifications d'antécédents strictes, et nos véhicules sont régulièrement entretenus pour assurer votre sécurité ",
      about11:"Pourquoi choisir Blainville Taxi?",
      about12:"Chaque membre de notre équipe de chauffeurs vous accueille avec un sourire chaleureux et une attitude professionnelle, garantissant ainsi un voyage paisible grâce à leur calme et leur gentillesse",
          service1:"Blainville Taxi vous propose des services adaptés à tous les types de déplacements et autres, disponibles 24 heures sur 24 et tous les jours",
          service2:"Chez Blainville Taxi, nous offrons des services adaptés à tous et à tous les types de déplacements, tous les jours et 24 heures sur 24",
          service3:"service de taxi",
          service4:"service de transport vers l'aéroport avec ou sans réservation",
          service5:"LIVRAISON DEs COLIS",
          service6:"Animaux À BORD",
          service7:"réservation pour un rendez-vous médical",
          service8:"transport long distance",
          service9:"DÉVERROUILLER UNE PORTIÈRE",
          service10:"SURVOLTER UNE BATTERIE",
          service11:"Notre engagement envers un service exceptionnel commence dès la réservation",
          service12:"Avec des options de priorité disponibles, et se poursuit à travers l'expertise de nos chauffeurs professionnels. Chaque trajet est conçu pour offrir une expérience fluide et conviviale, du début à la fin",
        contact1:"Avec Blainville Taxi, bénéficiez en tout temps de services de transport fiables et sécuritaires!",
        book1:"Besoin d'un taxi? Nous sommes là pour vous, 24h/24, 7j/7",
        book2:"nos chauffeurs sont disponibles 24 heures sur 24 et 7 jours sur 7",
        }
      },
      en: {
        translation: {
          acceuil: 'home',
          propos: 'about',
          services: 'services',
          contacts: 'contacts',
          commander:'book a taxi',
          contacter:'contact us',
          home1: 'Are you looking for a taxi?',
          home2 :'Welcome to Blainville Taxi',
          home3:`Your trusted partner for all your journeys in safety and comfort. Whether it's an airport transfer or a simple ride around town, we're here to provide you with a personalized solution. Book your next ride with us now and enjoy quality service in Quebec City and its surroundings!`,
          home4:'BLAINVILLE TAXI: A QUICK SOLUTION JUST A CALL AWAY',
          home5:'Turn to the services of Taxi Blainville at any hour of the day or night! Benefit from reliable, punctual, and attentive taxi services, and travel aboard vehicles equipped with all the essential amenities for your comfort and safety. For any questions or inquiries, we invite you to contact us by phone or email.',
          home6:'Diverse services to make your life easier',
          home7:'No matter your destination! Take advantage of advantageous fixed rates on certain routes and particularly popular services. Explore them now to plan your next getaway with peace of mind!',
          home8:'Our priority: your satisfaction',
          home9:`Our primary goal is your complete satisfaction. Available 24/7, we are here to meet all your transportation needs. Whether it's booking online or by phone, we invite you to reserve your next trip now and let us accompany you with peace of mind during your travels`,
          foot1:'address',
          foot2:"1039 rue de la bretagne Blainville Québec J7C2Z8",
        foot3:"schedule",
        foot4:"Service areas",
          about1:"To travel comfortably and quickly, choose Blainville Taxi",
      about2:"Who are we?",
      about3:"At Blainville Taxi, we are not just a transportation company, but much more. Our foundation rests on a clear vision: providing convenient, reliable, and innovative travel solutions. We are committed to delivering an exceptional travel experience to each of our passengers.",
     about4:"Exceptional transportation solutions are available",
     about5:"Availability",
     about6:"Blainville Taxi is here for you, whether you have a late-night flight or an early morning meeting", 
     about7:"Competitive prices",
     about8:"We offer competitive rates without sacrificing service quality. Our commitment is to provide you with excellent value for money"
      , about9:"Safety first",
      about10:"We prioritize your safety. Our licensed drivers undergo strict background checks, and our vehicles are regularly maintained to ensure your safety.",
about11:"Why choose Blainville Taxi?",
about12:"Every member of our driver team welcomes you with a warm smile and a professional attitude, ensuring a peaceful journey through their calm and kindness",
service1:"Blainville Taxi offers services tailored to all types of travels and more, available 24 hours a day, every day",
service2:"At Blainville Taxi, we offer services tailored to everyone and all types of trips, every day, 24 hours a day" ,  
service3:"Taxi service",
service4:"Airport transportation service with or without reservation",
service5:"Parcel delivery",
service6:"Pets on board",
service7:"Booking for a medical appointment",
service8:"Long-distance transportation",
service9:"unlock a door",
service10:"Jump-starting a battery",
service11:"Our commitment to exceptional service begins from the moment of booking",
service12:"With priority options available, our commitment to exceptional service continues through the expertise of our professional drivers. Every journey is designed to provide a smooth and friendly experience from start to finish",
contact1:"With Blainville Taxi, enjoy reliable and safe transportation services at all times!",
book1:"Need a taxi? We're here for you, 24/7",
book2:"Our drivers are available 24 hours a day, 7 days a week",
}
    },
    },
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n;
