import "./style.css";
import React from "react";
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import Footer from "../../Components/Footer";
import homePic1 from "../../images/homePic1.jpg";
import homePic2 from "../../images/homePic2.jpg";
import homePic3 from "../../images/homePic3.jpg";
import ScrollToTop from "../../Components/scrollup";
import { Button } from "@mui/material";
import service1 from "../../images/service1.jpg"
import service2 from "../../images/service2.jpg"
import service3 from "../../images/service3.jpg"
import { useTranslation } from 'react-i18next';
export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="home" id="acceuil">
      <ScrollToTop />
      <div className="homeTop">
        <img src={homePic1} alt="taxi" className="homePic1" />
        <div>
          <h3>{t('home1')}</h3>
          <LocalTaxiIcon sx={{ fontSize: { lg: "34px", md: "30px", sm: "32px", xs: "22px" }}}/>
          <h4>{t('home2')}</h4>
          <p>
          {t('home3')}
          </p>
          <a href="/book">
          <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: 2 }}
          >
              {t('commander')}
          </Button>
          </a>
        </div>
      </div>
      <div className="homeMiddle3">
        <div>
          <h4>  {t('home4')}</h4>
          <p>
          {t('home5')}
          </p>
          <a href="/contacts">
          <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: 2 }}
          >
            {t('contacter')}
          </Button>
          </a>
        </div>
        <img src={homePic3} alt="taxi2" className="homePic2" />
      </div>
      <div className="homeMiddle2">
        <div className="homeMiddle22">
        <h4>
        {t('home6')}
        </h4>
        <p>
        {t('home7')}
        </p>
        </div>
        <div className="serviceBlock">
        <img src={service1} alt="service1" className="servicePic" />
        <img src={service2} alt="service2" className="servicePic" />
        <img src={service3} alt="service3" className="servicePic" />
        </div>
      </div>
      <div className="homeMiddle">
        <img src={homePic2} alt="taxi2" className="homePic2" />
        <div>
          <h4>  {t('home8')}</h4>
          <p>
          {t('home9')}
          </p>
          <a href="/book">
          <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: 2 }}
          >
             {t('commander')}
          </Button>
          </a>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
