
import PhoneIcon from '@mui/icons-material/Phone';
import './style.css';

export default function PhoneCall() {
 

  return (
    <div className='phoneBtn'>
       <a href="tel:(+1)438-221-0201" >
      <PhoneIcon  sx={{ fontSize: { lg: '32px' }, color: '#fff' }} />
      </a>
    </div>
  );
}
