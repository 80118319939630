/* eslint-disable jsx-a11y/iframe-has-title */
import "./style.css";
import React from "react";
import { useEffect,useState } from "react";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import { Button } from "@mui/material";
import contactPic from "../../images/bookPic.png"
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

export default function Book() {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(4);
  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(4);
      } else if (windowWidth >= 700) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(2);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: itemsPerPage,
    slidesToScroll: 1,
    className: "custom-slider",
  };
  return (
    <div className="contact" id="contact">
         <img src={contactPic} alt="couverture-quebec" className="couverturePic" />
     
      <ScrollToTop />
        <h2 className="locationTitle">{t('book1')}</h2>
        <div className="contactDiv">
        <form className="contactForm">
                    <div className='ContactTitle'>
                        <div className='contactBefore'></div>
                        <span className='ContactSpan'>Contact</span>
                    </div>
                    <div className='ContactTitle'>
                    <PlaceIcon sx={{color:'#002494',fontSize:{xs:'medium',lg:'large'}}}/>
                    <span className='contactDetails'>1039 RUE DE LA BRETAGNE BLAINVILLE QUÉBEC J7C2Z8</span>
                    </div>
                    <div className='ContactTitle'>
                    <PhoneIcon sx={{color:'#002494',fontSize:{xs:'medium',lg:'large'}}}/>
                    <span ><a href="tel:(+1)4382210201" className='contactDetails'>(+1) 438-221-0201</a></span>
                    </div>
                    <div className='ContactTitle'>
                    <ChatBubbleOutlineOutlinedIcon sx={{color:'#002494',fontSize:{xs:'medium',lg:'large'}}}/>
                    <span className='contactDetails'>MESSAGE US</span>
                    </div>
                    <input name="name" type="text" className="feedback-input" placeholder="Name" />
                    <input name="number" type="number" className="feedback-input" placeholder="Phone Number" />
                    <input name="email" type="email" className="feedback-input" placeholder="Email Adress" />
                    <textarea name="message" type="text" className="feedback-input" placeholder="Message" ></textarea>
                    <a className='footLink' href="mailto:medglaa@outlook.com">
                    <Button color="warning" variant='contained' sx={{width:'50%',marginTop:2
                ,fontSize:{xs:'small',sm:'medium',lg:'medium'}}}>
                        SEND Message
                        </Button>
                        </a> 
                </form>
        <div>
         
          <h3> {t('commander')}</h3>
            <span>
              <PhoneAndroidIcon
                sx={{
                  color: "#008DBD",
                  width: { lg: "50px", md: "50px", sm: "32px", xs: "22px" },
                }}
              />{" "}
              <a href="tel:(+1)438-221-0201" className="contact-link book">
                (+1) 438-221-0201
              </a>
            </span>
        </div>
         
        </div>
       
        
        <h3 > {t('book2')}</h3>
        <div>
        <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: '20px' }}
          >
            {t('contacter')}
          </Button>
          </div>
        <Slider {...settings}>
          {itemData.map((item) => (
              <div className="bookPicContainer">
                <a href={`/bons-plans/${item}`}>
                  <img
                    src={require(`../../images/${item}.jpg`)}
                    alt={item}
                    className="bookPic"
                  />
                  
                </a>
              </div>
          ))}
        </Slider>
      <Footer />
    </div>
  );
}
const itemData = [
  "aboutDriverPic",
  "serv2",
  "serv3",
  "serv7",
  'aboutPic2'
];