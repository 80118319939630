import "./style.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from "@mui/icons-material/Email";
import logo from '../../images/logo.jpg'
import { useTranslation } from 'react-i18next';
export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
     
      <hr></hr>
      <div className="foots">
        <div className="foot">
         <img src={logo} alt="logo" className="footer-logo"/>
         
        </div>
        <div className="foot">
          <h4> Informations </h4> 
          <p className="footer-link">
         <strong style={{color:"#011",fontFamily:'Oswald'}}> {t('foot1')}</strong><br/><br/>
         {t('foot2')}
           </p>
           <p className="footer-link">
             <strong style={{color:"#011",fontFamily:'Oswald'}}>  {t('foot3')}</strong><br/><br/>
             7j/7j - 
                24h/24h
           </p>
        </div>

        <div className="foot">
          <h4>contacts</h4>
          <a href="tel:438-221-0201" className="footer-link">
          (+1) 438-221-0201
          </a>
          <div>
          <a
            href="https://www.facebook.com/profile.php?id=61561360553464"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon color="warning" sx={{width:{lg:36,md:'34px',sm:'24px',xs:'20px'}}}/>
          </a>
          <a
            href="https://www.instagram.com/taxi_blainville/"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon color="warning" sx={{width:{lg:36,md:'32px',sm:'22px',xs:'18px'}}}/>
          </a>
                  <a href="mailto:medglaa@outlook.com"  className="footer-link">
                    <EmailIcon color="warning" sx={{width:{lg:36,md:'32px',sm:'22px',xs:'18px'}}} />
                  </a>
          </div>
        </div>
        <div className="lastFoot">
        <h5 style={{ color: "GrayText" }}>
          © 2024, TAXI BLAINVILLE. All Rights Reserved.
        </h5>
      </div>
      </div>
      <hr></hr>
      <div className="zoneService">
      <h2>{t('foot4')}</h2>
        <ul>
       <li>Blainville</li>  <li>Saint Thérèse</li> <li>Mirabel</li> <li>Rosemére</li>  <li>Saint Rose</li>  <li>Fabreville</li> 
       <li>Bois des Filion</li>  <li>Lorraine</li> <li>Boisbriand</li> <li>Autieul</li> <li>Saint Thérèse</li> <li>Mirabel</li>
      
</ul>
      </div>
    
    
    </div>
  );
}
