import "./style.css";
import React from "react";
import Footer from "../../Components/Footer";
import { Button } from "@mui/material";
import couverturePic from '../../images/aboutPic1.jpeg'
import aboutPic from '../../images/aboutPic2.jpg'
import about1 from '../../images/about1.png'
import about2 from '../../images/about2.png'
import about3 from '../../images/about3.png'
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import aboutDriver from '../../images/aboutDriverPic.jpg';
import { useTranslation } from 'react-i18next';
export default function About() {
  const { t } = useTranslation();
  return (
    <div className="about">
        <img src={couverturePic} alt="couverture-quebec" className="couverturePic" />
        <h2> {t('about1')}<LocalTaxiIcon color="warning" sx={{marginLeft:1}}/></h2>
        <div className="aboutContain">
        <div>
        <h3><strong>Taxi Blainville</strong>, {t('about2')}</h3>
        <p>{t('about3')}</p>
        <a href="/contacts">
        <Button
            color="warning"
            variant="contained"
            sx={{ fontFamily: "Poppins", marginTop: 2 }}
          >
           {t('contacter')}
          </Button>
          </a>
        </div>
        <img src={aboutPic} alt="blainville taxi" className="aboutPic" />
        </div>
        <h4>{t('about4')}</h4>
        <div className="aboutServices">
        
            <div>
          <img src={about1} alt="blainville taxi" className="aboutServicePic" />
        <h4>{t('about5')} 24/7</h4>
        <p>{t('about6')}</p>
        </div>
        <div>
          <img src={about2} alt="blainville taxi" className="aboutServicePic" />
        <h4>{t('about7')}</h4>
        <p>{t('about8')}</p>
        </div>
        <div>
          <img src={about3} alt="blainville taxi" className="aboutServicePic" />
        <h4>{t('about9')}</h4>
        <p>{t('about10')} </p>
        </div>
        </div>
        <div className="aboutContain2">
       
        <div>
   
        <h3>{t('about11')}</h3>
        <p>{t('about12')}</p>
        </div>
        <img src={aboutDriver} alt="blainville taxi" className="aboutDriverPic" />

        </div>
      <Footer/>
    </div>
  );
}
